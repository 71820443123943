import React from "react"
// import Helmet from "react-helmet"
import { Helmet } from "react-helmet"

export default React.memo(({ siteUrl, title }) => {
  const schema = [
    {
      "@context": "http://www.schema.org",
      "@type": "Restaurant",
      name: "Traditions on the Lake",
      telephone: "864-722-2800",
      url: "https://www.traditionsonthelake.com/",
      logo:
        "https://traditions-on-the-lake.s3.amazonaws.com/Traditions-Logo.png",
      image:
        "https://traditions-on-the-lake.s3.amazonaws.com/dining-room.jpg",
      description:
        "Located within Clemson's newest luxury hotel and resort, Traditions on the Lake offers travelers and locals classic American cuisine using fresh locally-sourced ingredients.",
      address: {
        "@type": "PostalAddress",
        streetAddress: "13500 Clemson Blvd.",
        addressLocality: "Seneca",
        addressRegion: "SC",
        postalCode: "29678",
        addressCountry: "United States",
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: "34.6915532",
        longitude: "-82.8558155",
      },
      hasMap: "https://goo.gl/maps/1TmLn6ApHU6w5Hku5",
      openingHours:
        "",
      priceRange: "$$",
      paymentAccepted: ["Cash", "Credit Card"],
      servesCuisine: ["Southern", "American", "Steaks", "Tacos"],
      hasMenu: "https://www.traditionsonthelake.com/menu/",
      acceptsReservations: "Yes",
      contactPoint: {
        "@type": "ContactPoint",
        telephone: "+1-864-722-2800",
        contactType: "Customer Service",
      },
    },
  ]
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
})
